'use client';
import Skeleton from '@mui/material/Skeleton';
import Cookies from 'js-cookie';
import { useState, useEffect, useRef } from 'react';

import { useUserStore } from '@/store/user';

import style from './mediaWithAuthHeader.module.scss';

function MediaWithAuthHeader({
    src,
    isVideo = false,
    alt,
    className,
    setImageSize,
    setVideoSize,
    ...props
}: {
    src: string;
    isVideo?: boolean;
    alt?: string;
    className?: string;
    setVideoSize?: ({ width, height }: { width: number; height: number }) => void;
    setImageSize?: ({ width, height }: { width: number; height: number }) => void;
}) {
    const [mediaUrl, setMediaUrl] = useState('');

    const imageSize = useRef<HTMLImageElement>(null);
    const videoSize = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const token =
                    useUserStore.getState().userInfo.access_token || Cookies.get('access');
                const headerParams: { Authorization?: string } = {};

                if (token) {
                    headerParams.Authorization = `Bearer ${token}`;
                }

                const response = await fetch(src, {
                    method: 'GET',
                    headers: headerParams
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const objectUrl = URL.createObjectURL(blob);
                    setMediaUrl(objectUrl);
                } else {
                    console.error(response.status);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (src) {
            fetchImage();
        }

        return () => {
            if (mediaUrl) {
                URL.revokeObjectURL(mediaUrl);
            }
        };
    }, [src]);

    if (!src) {
        return null;
    }

    if (!mediaUrl) {
        return (
            <Skeleton sx={{ bgcolor: 'grey.900', flex: 1, height: '100%' }} variant="rectangular" />
        );
    }

    return (
        <>
            {isVideo ? (
                <video
                    ref={videoSize}
                    className={`${style.video} ${className}`}
                    src={mediaUrl}
                    controls
                    controlsList="nodownload noremoteplayback"
                    muted
                    playsInline
                    loop
                    autoPlay
                    onContextMenu={e => e.preventDefault()}
                    onLoadedMetadata={() =>
                        setVideoSize &&
                        setVideoSize({
                            width: videoSize.current?.videoWidth ?? 0,
                            height: videoSize.current?.videoHeight ?? 0
                        })
                    }
                    {...props}
                />
            ) : (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    ref={imageSize}
                    className={`${style.image} ${className}`}
                    src={mediaUrl}
                    alt={alt}
                    {...props}
                    onLoad={() =>
                        setImageSize &&
                        setImageSize({
                            width: imageSize.current?.clientWidth ?? 0,
                            height: imageSize.current?.clientHeight ?? 0
                        })
                    }
                />
            )}
        </>
    );
}

export default MediaWithAuthHeader;
