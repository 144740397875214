import Link from 'next/link';

import { CurationListType } from '@/types/home';

import CurationCard from '../curationCard/curationCard';

import style from './curationList.module.scss';

function CurationList({
    dataList,
    title,
    href,
    hideTop = false,
    className
}: {
    dataList: CurationListType;
    title?: string;
    href?: string;
    hideTop?: boolean;
    className?: string;
}) {
    if (dataList.length === 0) {
        return null;
    }

    return (
        <div className={`${style.curationList} ${className}`}>
            {!hideTop && (
                <div className={style.top}>
                    <h6 className={style.title}>{title}</h6>
                    <Link href={href || '/'} className={style.more}>
                        查看更多
                    </Link>
                </div>
            )}

            <div className={style.mediaContent}>
                {dataList.map((curationData, idx) => (
                    <CurationCard curationData={curationData} key={idx} />
                ))}
            </div>
        </div>
    );
}

export default CurationList;
