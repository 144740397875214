const SessionStorageManager = {
    set(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    },

    get(key: string): string | null {
        return sessionStorage.getItem(key) || null;
    },

    remove(key: string): void {
        sessionStorage.removeItem(key);
    },

    clear(): void {
        sessionStorage.clear();
    }
};

export default SessionStorageManager;
