import { fetcher } from '@/lib/fetcher';

export interface FollowInfo {
    account: string;
    user_id: string;
    avatar: string;
    nickname: string;
    label: {
        tag: 'evil' | 'angel';
        icon: string;
        name: string;
        background: string;
        text: string;
        opacity: number;
    };
}

/** 拿到我的用戶追蹤列表 */
export const getMyFollowings = () => {
    return fetcher<FollowInfo[], null>({
        url: `/relationship/v1/me/followee`,
        method: 'GET'
    });
};

/** 拿到追蹤我的用戶列表 */
export const getMyFollower = () => {
    return fetcher<FollowInfo[], null>({
        url: `/relationship/v1/me/follower`,
        method: 'GET'
    });
};

/** 拿到是否追蹤此 CP */
export const getIsFollowById = (id: string) => {
    return fetcher<{ is_follow: boolean }, null>({
        url: `/relationship/v1/follow/${id}`,
        method: 'GET'
    });
};

/** 取消對方追蹤 */
export const unFollowAccount = (id: string) => {
    return fetcher({
        url: `/relationship/v1/follow/${id}`,
        method: 'DELETE'
    });
};

/** 追蹤對方 */
export const followAccount = (id: string) => {
    return fetcher({
        url: `/relationship/v1/follow/${id}`,
        method: 'POST'
    });
};
