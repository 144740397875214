'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useState, useEffect } from 'react';

import BaseLink from '@/components/baseLink/baseLink';
import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';
import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { useBaseStore } from '@/store/base';
import { useCpMediaStore } from '@/store/cpMedia';
import { useUserStore } from '@/store/user';
import { CurationType, CurationVideoType, CurationDramaType } from '@/types/home';
import { convertSecondsToTime } from '@/utils/timeConverter';

import PlayIcon from './img/play.svg';

import style from './curationCard.module.scss';

type pathMapType = 'video' | 'image' | 'drama';

function CurationCard({ curationData }: { curationData: CurationType }) {
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const isLogin = useUserStore.use.isLogin();
    const [postUrl, setPostUrl] = useState('');
    const pathMap = {
        video: 'v',
        image: 'i',
        drama: 'p'
    };

    const switchCpMedia = useCpMediaStore.use.switchCpMedia();
    const setTargetMedia = useCpMediaStore.use.setTargetMedia();
    const setKeepUrl = useCpMediaStore.use.setKeepUrl();

    useEffect(() => {
        if (curationData.type === 'drama') {
            setPostUrl(
                `/cp/${curationData.userId}/p/${(curationData as CurationDramaType).dramaId}/e/${
                    curationData.creationId
                }`
            );
        } else {
            setPostUrl(
                `/cp/${curationData.userId}/${pathMap[curationData.type as pathMapType]}/${
                    curationData.creationId
                }`
            );
        }
    }, [curationData]);

    const currentPath = usePathname();
    const handleRoute = () => {
        if (!isLogin) {
            setLoginPopup();
            return;
        }
        setKeepUrl(currentPath);
        switchCpMedia(true);
        setTargetMedia({
            userId: curationData.userId,
            detailId: curationData.creationId,
            type: curationData.type,
            episodeId: (curationData as CurationDramaType).dramaId
        });
    };

    return (
        <BaseLink className={style.curationCard} href={postUrl} noRouter onClick={handleRoute}>
            <div className={style.top}>
                <CpAvatar src={curationData.avatar} tag={curationData.label} />
                {curationData.type !== 'image' && (
                    <div className={style.duration}>
                        <PlayIcon />
                        {convertSecondsToTime(
                            (curationData as CurationVideoType | CurationDramaType).duration
                        )}
                    </div>
                )}
            </div>
            <>
                {curationData.diamond === 0 ? (
                    <>
                        <Image
                            alt=""
                            src={curationData.cover}
                            fill
                            sizes="100%"
                            style={{ objectFit: 'contain' }}
                            className={style.img}
                        />
                    </>
                ) : (
                    <>
                        {curationData.unlocked ? (
                            <MediaWithAuthHeader alt="" src={curationData.cover} />
                        ) : (
                            <Image
                                alt=""
                                src={curationData.cover}
                                fill
                                sizes="100%"
                                style={{ objectFit: 'contain' }}
                                className={style.img}
                            />
                        )}
                    </>
                )}
            </>
            <div className={style.textbar}>
                <div className={style.text}>{curationData.title}</div>
            </div>
        </BaseLink>
    );
}

export default CurationCard;
