export const timestampToHHMM = (timestamp: string) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

export function timestampToMMDD(timestamp: string) {
    const targetTime = new Date(timestamp);
    return `${targetTime.getMonth() + 1}月${targetTime.getDate()}日`;
}
