import Link from 'next/link';

import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';

import style from './cpInfoCard.module.scss';

interface CpAvatarType {
    userName: string;
    userId: string;
    isOnline?: boolean;
    subText?: string;
    src: string;
    tag: string;
    className?: string;
    width?: number;
    height?: number;
    alt?: string;
    linkToCpPage?: boolean;
    lightStyle?: boolean;
    onClick?: () => void;
}

function CpInfoCard({
    userName,
    userId,
    isOnline = false,
    subText,
    src,
    tag,
    className,
    width = 46,
    height = 46,
    alt = '',
    linkToCpPage = true,
    lightStyle = false,
    onClick,
    ...props
}: CpAvatarType) {
    return (
        <>
            {linkToCpPage ? (
                <Link
                    className={`${style.cpInfoCard} ${className}`}
                    href={`/cp/${userId}/i`}
                    onClick={onClick}
                    {...props}
                >
                    <CpAvatar src={src} tag={tag} alt={alt} width={width} height={height} />
                    <div className={`${style.info} ${lightStyle && style.light}`}>
                        <div className={style.text}>
                            <div className={`${style.userName} ${isOnline && style.onlineType}`}>
                                {userName}
                            </div>
                            {isOnline && <div className={style.online} />}
                        </div>
                        <div className={style.subText}>{subText}</div>
                    </div>
                </Link>
            ) : (
                <div className={`${style.cpInfoCard} ${className}`} onClick={onClick} {...props}>
                    <CpAvatar src={src} tag={tag} alt={alt} width={width} height={height} />
                    <div className={`${style.info} ${lightStyle && style.light}`}>
                        <div className={style.text}>
                            <div className={`${style.userName} ${isOnline && style.onlineType}`}>
                                {userName}
                            </div>
                            {isOnline && <div className={style.online} />}
                        </div>
                        <div className={style.subText}>{subText}</div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CpInfoCard;
