import { fetcher } from '@/lib/fetcher';
import {
    CpInfoType,
    CpImageType,
    CpVideoType,
    CpPornAlbumList,
    CpPornAlbumInfo,
    CpPornAlbumVideoList,
    CpPornAlbumEpisodeVideo,
    CpPornAlbumTrailerVideo
} from '@/types/cpType';

export const getCpInfo = (userId: string) => {
    return fetcher<CpInfoType, null>({
        url: `/couple/v1/profile/${userId}`,
        method: 'GET'
    });
};

export const getCpImageList = ({
    userId,
    limit,
    page,
    serverAccess
}: {
    userId: string;
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<CpImageType[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/${userId}/image`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

export const getCpImageInfo = ({
    userId,
    imageId,
    serverAccess
}: {
    userId: string;
    imageId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpImageType, null>(
        {
            url: `/couple/v1/creation/${userId}/image/${imageId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

export const unlockCpImage = ({ userId, imageId }: { userId: string; imageId: string }) => {
    return fetcher<CpImageType, null>({
        url: `/couple/v1/creation/${userId}/image/${imageId}/unlock`,
        method: 'POST'
    });
};

export const getCpVideoList = ({
    userId,
    limit,
    page,
    serverAccess
}: {
    userId: string;
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<CpVideoType[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/${userId}/video`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

export const getCpVideoInfo = ({
    userId,
    videoId,
    serverAccess
}: {
    userId: string;
    videoId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpVideoType, null>(
        {
            url: `/couple/v1/creation/${userId}/video/${videoId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

export const unlockCpVideo = ({ userId, videoId }: { userId: string; videoId: string }) => {
    return fetcher<CpVideoType, null>({
        url: `/couple/v1/creation/${userId}/video/${videoId}/unlock`,
        method: 'POST'
    });
};

export const getCpPornAlbumList = ({
    userId,
    limit,
    page,
    serverAccess
}: {
    userId: string;
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<CpPornAlbumList[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/${userId}/drama`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 單一影集資訊
export const getCpPornAlbumInfo = ({ userId, albumId }: { userId: string; albumId: string }) => {
    return fetcher<CpPornAlbumInfo, null>({
        url: `/couple/v1/creation/${userId}/drama/${albumId}`,
        method: 'GET'
    });
};
// 單一影集列表
export const getCpPornAlbumVideoList = ({
    userId,
    albumId,
    limit,
    page
}: {
    userId: string;
    albumId: string;
    limit: number;
    page: number;
}) => {
    return fetcher<CpPornAlbumVideoList[], { limit: number; page: number }>({
        url: `/couple/v1/creation/${userId}/drama/${albumId}/episode`,
        method: 'GET',
        data: {
            limit,
            page
        }
    });
};

// 影片
export const getCpPornAlbumEpisodeVideoInfo = ({
    userId,
    albumId,
    episodeId,
    serverAccess
}: {
    userId: string;
    albumId: string;
    episodeId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpPornAlbumEpisodeVideo, null>(
        {
            url: `/couple/v1/creation/${userId}/drama/${albumId}/episode/${episodeId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

// 解鎖影片
export const unlockCpPornAlbumEpisodeVideo = ({
    userId,
    albumId,
    episodeId
}: {
    userId: string;
    albumId: string;
    episodeId: string;
}) => {
    return fetcher<CpPornAlbumEpisodeVideo, null>({
        url: `/couple/v1/creation/${userId}/drama/${albumId}/episode/${episodeId}/unlock`,
        method: 'POST'
    });
};

// 預告片
export const getCpPornAlbumTrailerVideoInfo = ({
    userId,
    albumId,
    trailerId
}: {
    userId: string;
    albumId: string;
    trailerId: string;
}) => {
    return fetcher<CpPornAlbumTrailerVideo, null>({
        url: `/couple/v1/creation/${userId}/drama/${albumId}/trailer/${trailerId}`,
        method: 'GET'
    });
};

// 對創作按讚
export const postLikeMedia = ({
    userId,
    type,
    id
}: {
    userId: string;
    type: string;
    id: string;
}) => {
    return fetcher<null, null>({
        url: `/couple/v1/creation/${userId}/${type}/${id}/favor`,
        method: 'POST'
    });
};

// 取消對創作按讚
export const cancelLikeMedia = ({
    userId,
    type,
    id
}: {
    userId: string;
    type: string;
    id: string;
}) => {
    return fetcher<null, null>({
        url: `/couple/v1/creation/${userId}/${type}/${id}/favor`,
        method: 'DELETE'
    });
};

// 對謎片按讚
export const postLikeDrama = ({
    userId,
    dramaId,
    type,
    id
}: {
    userId: string;
    dramaId: string;
    type: string;
    id: string;
}) => {
    return fetcher<null, null>({
        url: `/couple/v1/creation/${userId}/drama/${dramaId}/${type}/${id}/favor`,
        method: 'POST'
    });
};

// 對謎片按讚
export const cancelLikeDrama = ({
    userId,
    dramaId,
    type,
    id
}: {
    userId: string;
    dramaId: string;
    type: string;
    id: string;
}) => {
    return fetcher<null, null>({
        url: `/couple/v1/creation/${userId}/drama/${dramaId}/${type}/${id}/favor`,
        method: 'DELETE'
    });
};
