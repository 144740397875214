import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { unFollowAccount, followAccount } from '@/api/relationship/relationship';
import CpInfoCard from '@/components/cp/cpInfoCard/cpInfoCard';
import { useUserInfoCompletedHook } from '@/features/userInfoCompleted/userInfoCompleted';
import { useValidatePhoneHook } from '@/features/validatePhone/validatePhone';
import { useBaseStore } from '@/store/base';
import { useChatStore } from '@/store/chat';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import { ExploreCardType } from '@/types/home';

import AngelIcon from './img/angel.svg';
import ChatIcon from './img/chat.svg';
import EvilIcon from './img/evil.svg';
import FavoriteIcon from './img/favorite.svg';
import UnfavoriteIcon from './img/unFavorite.svg';

import style from './cpCoverCard.module.scss';

function CpImgCard({ cpPopular }: { cpPopular: ExploreCardType }) {
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const switchChat = useChatStore.use.switchChat();
    const setCurrentChat = useChatStore.use.setCurrentChat();
    const userInfo = useUserStore.use.userInfo();
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const { checkValidatePhone } = useValidatePhoneHook();
    const { checkUserInfoCompleted } = useUserInfoCompletedHook();
    const [isFollow, setIsFollow] = useState(cpPopular.isFollowed || false);
    const installPWA = useBaseStore.use.installPWA();

    const cpTypeIconMap = {
        evil: { icon: <EvilIcon />, title: '小惡魔' },
        angel: { icon: <AngelIcon />, title: '小天使' }
    };

    const handleChat = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (!isLogin) {
            setLoginPopup();
            return;
        }
        if (checkUserInfoCompleted() || checkValidatePhone()) {
            return;
        }
        setCurrentChat(cpPopular);
        switchChat();
    };

    const handleFavoriteBtn = async (event: React.MouseEvent<HTMLElement>) => {
        if (!isLogin) {
            setLoginPopup();
            return;
        }

        if (checkUserInfoCompleted() || checkValidatePhone()) {
            return;
        }

        event.preventDefault();

        if (userInfo.user_id === cpPopular.userId) {
            setSnackMessage({ message: '不可追蹤自己！' });
            return;
        }

        if (isFollow) {
            const res = await unFollowAccount(cpPopular.userId);
            if (res.status === 200) {
                setIsFollow(false);
                setSnackMessage({ message: '已取消追蹤' });
            }
        } else {
            const res = await followAccount(cpPopular.userId);
            if (res.status === 200) {
                setIsFollow(true);
                setSnackMessage({ message: '已追蹤' });
                setTimeout(() => {
                    installPWA();
                }, 500);
            }
        }
    };

    return (
        <Link className={style.cpImgCard} href={`/cp/${cpPopular.userId}/i`}>
            <div className={style.infoBar}>
                <CpInfoCard
                    userId={cpPopular.userId}
                    src={cpPopular.avatar}
                    userName={cpPopular.nickname}
                    subText={`@${cpPopular.account}`}
                    tag={cpPopular.label}
                    linkToCpPage={false}
                />
                {userInfo.user_id !== cpPopular.userId && (
                    <div className={style.right}>
                        <div
                            className={`${style.followBox} ${isFollow && style.isFollow}`}
                            onClick={handleFavoriteBtn}
                        >
                            {isFollow ? (
                                <FavoriteIcon className={style.icon} />
                            ) : (
                                <UnfavoriteIcon className={style.icon} />
                            )}
                        </div>

                        <div className={style.chatBtn} onClick={event => handleChat(event)}>
                            <ChatIcon />
                        </div>
                    </div>
                )}
            </div>
            <div className={style.tagBar}>
                <div className={`${style.cpType} ${style[cpPopular.label]}`}>
                    {cpTypeIconMap[cpPopular.label as 'evil' | 'angel'].icon}
                    {cpTypeIconMap[cpPopular.label as 'evil' | 'angel'].title}
                </div>
                {cpPopular.cpTags.map((tag, tagIdx) => (
                    <div className={style.tag} key={`${tag}_${tagIdx}`}>
                        # {tag}
                    </div>
                ))}
            </div>
            <div className={style.imageBox}>
                {cpPopular.cpCovers.map((img, imgIdx) => (
                    <div className={style.imgCaed} key={imgIdx}>
                        <Image
                            alt=""
                            src={img}
                            fill
                            sizes="100%"
                            style={{ objectFit: 'cover' }}
                            className={style.img}
                        />
                    </div>
                ))}
            </div>
        </Link>
    );
}

export default CpImgCard;
