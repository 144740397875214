import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';

type MediaInfo = {
    userId: string;
    detailId: string;
    type: string;
    trailerId?: string;
    episodeId?: string;
};

interface baseState {
    openCpMedia: boolean;
    switchCpMedia: (status: boolean) => void;
    setTargetMedia: (target: MediaInfo) => void;
    target: MediaInfo;
    keepUrl: string;
    setKeepUrl: (url: string) => void;
    close: () => void;
}

let isInit = true;
let useCpMediaStore: StoreWithSelectors<baseState>;

const initialState = (set: (updater: (state: baseState) => Partial<baseState>) => void) => ({
    openCpMedia: false,
    target: {
        userId: '',
        detailId: '',
        type: '',
        trailerId: '',
        episodeId: ''
    },
    switchCpMedia: (status: boolean) => {
        set(() => ({
            openCpMedia: status
        }));
    },
    setTargetMedia: (target: MediaInfo) => {
        set(() => ({
            target
        }));
    },
    close: () => {
        set(() => ({
            openCpMedia: false,
            target: {
                userId: '',
                detailId: '',
                type: '',
                trailerId: '',
                episodeId: ''
            }
        }));
    },
    keepUrl: '',
    setKeepUrl: (url: string) => {
        set(() => ({
            keepUrl: url
        }));
    }
});

const createCpMediaStore = () => {
    if (isInit) {
        useCpMediaStore = initStore<baseState>(initialState);
        isInit = false;
    }
};

export { createCpMediaStore, useCpMediaStore };
