import { useRouter } from 'next/navigation';
import { MouseEventHandler, ReactNode } from 'react';

interface Props {
    href: string;
    title?: string;
    onClick?: () => void;
    children: ReactNode;
    noRouter?: boolean;
    className?: string;
}

function BaseLink({ children, href, title, onClick, noRouter, className }: Props) {
    const router = useRouter();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = e => {
        e.preventDefault();
        if (!noRouter) router.push(href);
        onClick && onClick();
    };

    return (
        <a href={href} title={title} className={className} onClick={handleClick}>
            {children}
        </a>
    );
}

export default BaseLink;
