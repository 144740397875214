import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import {
    postLikeMedia,
    cancelLikeMedia,
    postLikeDrama,
    cancelLikeDrama
} from '@/api/couple/cpInfo';
import CpInfoCard from '@/components/cp/cpInfoCard/cpInfoCard';
import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { useUserInfoCompletedHook } from '@/features/userInfoCompleted/userInfoCompleted';
import { useValidatePhoneHook } from '@/features/validatePhone/validatePhone';
import { timestampToMMDD } from '@/lib/timeHandle';
import { useBaseStore } from '@/store/base';
import { useChatStore } from '@/store/chat';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import { CpTagType } from '@/types/cpType';
import { ExplorePostImageType, ExplorePostVideoType, ExplorePostDramaType } from '@/types/home';
import { convertSecondsToTime } from '@/utils/timeConverter';

import ChatIcon from './img/chat.svg';
import DiamondIcon from './img/diamond.svg';
import FavoriteIcon from './img/favorite.svg';
import GiftsIcon from './img/gifts.svg';
import LileCountIcon from './img/lileCount.svg';
import PlayIcon from './img/play.svg';
import ShareIcon from './img/share.svg';
import UnfavoriteIcon from './img/unFavorite.svg';
import UnLockIcon from './img/unLock.svg';
import ViewIcon from './img/view.svg';

import style from './postCard.module.scss';

type pathMapType = 'video' | 'image' | 'drama';

function PostCard({
    postData,
    showFollowBtn = true
}: {
    postData: ExplorePostImageType | ExplorePostVideoType | ExplorePostDramaType;
    showFollowBtn?: boolean;
}) {
    const isLogin = useUserStore.use.isLogin();
    const userInfo = useUserStore.use.userInfo();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const switchChat = useChatStore.use.switchChat();
    const setCurrentChat = useChatStore.use.setCurrentChat();
    const { checkValidatePhone } = useValidatePhoneHook();
    const { checkUserInfoCompleted } = useUserInfoCompletedHook();
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const baseUrl =
        useBaseStore.getState().envParams === 'prod'
            ? process.env.NEXT_PUBLIC_BASE_WEBSITE_URL_PROD
            : process.env.NEXT_PUBLIC_BASE_WEBSITE_URL_DEV;
    const pathMap = {
        video: 'v',
        image: 'i',
        drama: 'p'
    };
    const [postUrl, setPostUrl] = useState('');
    const [likeCount, setLikeCount] = useState(postData.favorCount || 0);
    const [isLike, setIsLike] = useState(postData.isFavored || false);
    const installPWA = useBaseStore.use.installPWA();

    useEffect(() => {
        if (postData.type === 'drama') {
            setPostUrl(
                `/cp/${postData.userId}/p/${(postData as ExplorePostDramaType).dramaId}/e/${
                    postData.creationId
                }`
            );
        } else {
            setPostUrl(
                `/cp/${postData.userId}/${pathMap[postData.type as pathMapType]}/${
                    postData.creationId
                }`
            );
        }
    }, [postData]);

    const handleLogin = (event: React.MouseEvent<HTMLElement>) => {
        if (!isLogin) {
            event.preventDefault();
            setLoginPopup();
        }
    };

    const handleShareSite = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (typeof navigator.share !== 'undefined') {
            await navigator
                .share({
                    title: 'WHyWHy',
                    url: `${baseUrl}${postUrl}`
                })
                .then(() => {
                    setSnackMessage({
                        message: '分享成功！'
                    });
                })
                .catch(async error => {
                    await navigator.clipboard.writeText(`${baseUrl}${postUrl}`);
                    console.error(error);
                });
        } else {
            await navigator.clipboard.writeText(`${baseUrl}${postUrl}`);
            setSnackMessage({
                message: '已複製分享連結！'
            });
        }

        return null;
    };

    const fetchLike = async () => {
        const params: { userId: string; type: string; id: string; dramaId?: string } = {
            userId: postData.userId,
            type: '',
            id: postData.creationId
        };

        if (postData.type === 'drama') {
            params.type = 'episode';
            params.dramaId = (postData as ExplorePostDramaType)?.dramaId;
        } else {
            if (postData.type === 'video') {
                params.type = 'video';
            } else {
                params.type = 'image';
            }
        }

        const res =
            postData.type === 'drama'
                ? await postLikeDrama(
                      params as { userId: string; dramaId: string; type: string; id: string }
                  )
                : await postLikeMedia(params);

        if (res.status === 200) {
            setLikeCount(count => count + 1);
            setSnackMessage({ message: '已按讚' });
            setIsLike(true);
            installPWA();
        }
    };

    const fetchUnLike = async () => {
        const params: { userId: string; type: string; id: string; dramaId?: string } = {
            userId: postData.userId,
            type: '',
            id: postData.creationId
        };

        if (postData.type === 'drama') {
            params.type = 'episode';
            params.dramaId = (postData as ExplorePostDramaType)?.dramaId;
        } else {
            if (postData.type === 'video') {
                params.type = 'video';
            } else {
                params.type = 'image';
            }
        }

        const res =
            postData.type === 'drama'
                ? await cancelLikeDrama(
                      params as { userId: string; dramaId: string; type: string; id: string }
                  )
                : await cancelLikeMedia(params);

        if (res.status === 200) {
            setLikeCount(count => count - 1);
            setSnackMessage({ message: '已取消讚' });
            setIsLike(false);
        }
    };

    const handleLikeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (!isLogin) {
            setLoginPopup();
            return;
        }

        if (isLike) {
            fetchUnLike();
        } else {
            fetchLike();
        }
    };

    const setReplyData = useChatStore.use.setReplyData();
    const setIsOpenSticker = useChatStore.use.setIsOpenSticker();
    const handleChat = (event: React.MouseEvent<HTMLElement>, type?: string) => {
        event.preventDefault();
        if (!isLogin) {
            setLoginPopup();
            return;
        }
        if (checkUserInfoCompleted() || checkValidatePhone()) {
            return;
        }
        if (type) {
            setIsOpenSticker(true);
        }

        setReplyData({
            img: postData.cover,
            description: postData.title,
            type: postData.type,
            id: postData.creationId
        });
        setCurrentChat({
            ...postData,
            ...{ user_id: postData.userId, label: postData.label as CpTagType }
        });

        switchChat();
    };

    return (
        <div className={style.postCard}>
            <div className={style.top}>
                <CpInfoCard
                    userId={postData.userId}
                    userName={postData.nickname}
                    subText={`@${postData.account}`}
                    src={postData.avatar}
                    tag={postData.label}
                />
            </div>
            <Link className={style.mediaConetent} href={postUrl} onClick={handleLogin}>
                {postData.diamond === 0 ? (
                    <Image
                        alt=""
                        src={postData.cover}
                        fill
                        sizes="100%"
                        style={{ objectFit: 'contain' }}
                        className={style.img}
                    />
                ) : (
                    <>
                        {postData.unlocked ? (
                            <MediaWithAuthHeader alt="" src={postData.cover} />
                        ) : (
                            <Image
                                alt=""
                                src={postData.cover}
                                fill
                                sizes="100%"
                                style={{ objectFit: 'contain' }}
                                className={style.img}
                            />
                        )}
                    </>
                )}

                {postData.type !== 'image' && (
                    <div className={style.duration}>
                        <PlayIcon />
                        {convertSecondsToTime(
                            (postData as ExplorePostVideoType | ExplorePostDramaType).duration
                        )}
                    </div>
                )}
                {postData.userId !== userInfo.user_id && showFollowBtn && (
                    <div className={style.favoriteBox} onClick={handleLikeClick}>
                        {isLike ? (
                            <FavoriteIcon className={style.icon} />
                        ) : (
                            <UnfavoriteIcon className={style.icon} />
                        )}
                    </div>
                )}
            </Link>
            <div className={style.bottom}>
                <div className={style.infoBox}>
                    <div className={style.infoBar}>
                        <div className={style.info}>
                            <LileCountIcon /> {likeCount}
                        </div>
                        <div className={style.info}>
                            <ViewIcon /> {postData.viewCount}
                        </div>
                        {postData.diamond > 0 && (
                            <>
                                <div className={style.info}>
                                    <DiamondIcon /> {postData.diamond}鑽
                                </div>
                                <div className={style.info}>
                                    <UnLockIcon /> {postData.unlockCount}
                                </div>
                            </>
                        )}
                    </div>
                    <div className={style.text}>{postData.title}</div>

                    <div className={style.time}>{timestampToMMDD(postData.publishDate)}</div>
                </div>
                <div className={style.operateBar}>
                    <ShareIcon className={style.iconBtn} onClick={handleShareSite} />
                </div>
            </div>
            <div className={style.chatBar}>
                <div className={style.chatBtn} onClick={event => handleChat(event)}>
                    <ChatIcon /> 開啟你與她的新話題！
                </div>
                <div className={style.giftBtn} onClick={event => handleChat(event, 'gifts')}>
                    <GiftsIcon />
                </div>
            </div>
        </div>
    );
}

export default PostCard;
