import Image from 'next/image';

import style from './cpAvatar.module.scss';

interface CpAvatarType {
    src: string;
    tag: string;
    className?: string;
    width?: number;
    height?: number;
    alt?: string;
    onClick?: () => void;
}

function CpAvatar({
    src,
    tag,
    className,
    width = 46,
    height = 46,
    alt = '',
    onClick,
    ...props
}: CpAvatarType) {
    return (
        <div
            className={`${style.cpAvatar} ${style[tag]} ${className}`}
            onClick={onClick}
            {...props}
        >
            <Image className={style.avatarImg} src={src} height={height} width={width} alt={alt} />
        </div>
    );
}

export default CpAvatar;
